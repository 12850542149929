@import '../utils/shadow';
@import '../utils/utility';

.component-menu-bar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  box-sizing: border-box;

  .menu-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    padding: 12px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    @include shadow(1);

    .burger-icon {
      cursor: pointer;
      border: 0;
      background: none;
    }
  }

  .the-menu-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    z-index: 200;

    aside {
      position: absolute;
      color: #fff;
      top: 0;
      left: 0;
      height: 100%;
      overflow: auto;
      width: 85vw;
      max-width: 300px;
      background-color: #132330;
      z-index: 200;
      transition: all 0.4s cubic-bezier(.25,.8,.25,1);
      box-shadow: 0 5px 10px rgba(0,0,0,0.19), 6px 6px 6px rgba(0,0,0,0.23);

      .close {
        position: absolute;
        top: 0px;
        right: 4px;
        padding: 12px;
        border: 0;
        background: none;
        color: #fff;
        font-size: 22px;
        font-weight: 600;
      }

      header {
        padding: 24px 36px 36px 12px;
        h1 {
          font-size: 12px;
          font-weight: 300;
          letter-spacing: 3px;
          margin-bottom: 6px;
        }

        h2 {
          font-size: 13px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 3px;
          text-transform: uppercase;
        }
      }

      ul {
        li {
          display: block;
          cursor: pointer;
          padding: plf(3);

          .btn-menu {
            text-decoration: none;
            display: block;
            color: inherit;
          }

          span {
            margin-left: 36px;
          }

          span.badge {
            margin-left: 12px;
          }
        }

        li:active {
          background-color: #233350;
        }
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      transition: all 0.4s cubic-bezier(.25,.8,.25,1);
    }
  }

  .badge {
    background-color: #fa3e3e;
    border-radius: 10px;
    color: white;
    padding: 1px 6px;
    font-size: 10px;
    vertical-align: middle;
    z-index: 2;
  }

  .badge.abs {
    position: relative;
    top: -7px;
    left: -4px;
    padding: 0 5px;
  }
}