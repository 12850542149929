@import '../../framework/utils/variables';
@import '../../framework/utils/static';
@import '../../framework/utils/shadow';

.scope-app {
  background-color: #e8e8e8;
  font-family: $base-font;
  position: relative;
  min-height: 90vh;
}

.paddingLeft {
	padding-left: $base-margin-whitespace;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
*::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
