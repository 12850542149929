@import '../utils/utility';

.af-carousel {

  .__control {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    .plain {
      padding: 0;
      border: none;
      background: none;
      outline: 0;
      font-size: 40px;
      font-weight: 900
    }

    .reverse {
      transform: rotate(180deg);
    }
  
  }
}