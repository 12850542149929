@import '../utils/utility';
@import '../utils/variables';

.af-tabular {
  min-height: auto;

  .row {
    display: flex;
    text-decoration: none;
    color: #000;
    margin: 0 mlf(1);
    padding: plf(1.5);
    border-bottom: 1px solid #ccc;

    .static-cell {
      flex: 1 1 auto;

      .header {
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: mlf(1);
      }

      .subheader {
        font-size: 0.9rem;
      }

      .status {
        padding-left: 8px
      }
    }

    .dynamic-cell {
      flex: 0 1 auto;
     
      &.--bold {
        font-weight: 600;
      }
    }

   

    .arrow-sign {
      flex: 0 1 auto;
      font-size: 0.8rem;
      padding-left: plf(3);
      align-self: center;
    }
  }

  &.--no-data {
    color: #aaa;
    padding: plf(3);
    text-align: center;
  }

  &.--branded {
    border: 1px solid #feb700;

    .row {
      color: #fff;
      border-bottom: 1px solid #feb700;
    }
  }

  .rotate {
    animation: rotateAnimation 0.5s linear ;
  }
  

  @keyframes rotateAnimation {
    0% {
      transform: rotateY(0);
    }
    100% {
      transform: rotateY(180deg);
    }

  }
  
}