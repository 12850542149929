.page-login {

  .header {
    background-color: #123123;
    color: white;
    padding: 16px;

    img {
      display: block;
      margin: 0 auto;
    }

    h1 {
      font-size: 22px;
      text-align: center;
      font-weight: 600;
      letter-spacing: 5px;
      text-transform: uppercase;
      padding: 22px 0;
    }
  }

  .input-element {
    padding: 8px;
    box-sizing: border-box;

    label {
      font-size: 12px;
      margin-bottom: 6px;
      letter-spacing: 2px;
      display: block;
      text-transform: uppercase;
      font-weight: 600;
    }

    input {
      display: block;
      width: 100%;
      padding: 6px 12px;
      font-size: 16px;
      letter-spacing: 2px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      outline: none;
      transition: all 0.5s cubic-bezier(.25,.8,.25,1);
    }

    input:focus {
      border: 1px solid rgba(45, 67, 200, 0.5);
    }
  }
}