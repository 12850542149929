@import '../utils/utility';
@import '../utils/variables';

.component-callout {
  padding: plf(3);
  
  &.--primary {
    color:#fff;
    background-color: $primary-color;
  }

  &.--success {
    color:#fff;
    background-color: $success-color;
  }

  &.--danger {
    color:#fff;
    background-color: $danger-color;
  }
}
