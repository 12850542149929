@import '../utils/utility';

.af-infinite-date-picker {

  .date-picker-overlay-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;

    .date-picker-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      min-width: 100vw;

      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.5);
        z-index: 100;
      }
  
      .date-picker-container {
        margin: 0 mlf(8);
        max-width: $page-max-width - plf(24);
        z-index: 200;
      }
    }
  }
}