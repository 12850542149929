@import '../utils/utility';
@import '../utils/shadow';

.af-navbar {
  display: flex;
  position: relative;
  min-width: 100%;
  min-height: 40px;
  padding: plf(1) plf(0.3);
  background-color: #1e0829;
  box-sizing: border-box;
  @include shadow(1);
}

.af-navbar-item {
  flex: 1 1 20%;
  padding: plf(1);
  margin: 0 mlf(0.3);
  text-decoration: none;
  display: block;
  color: #ffc600;
  background: linear-gradient(to bottom,#9100a7 0%,#670078 100%);
  display: block;
  border-radius: 5px;

  .__icon, .__label {
    text-align: center;
  }

  .__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 40px;

    .__icon {
      width: 32px;
    }
  }

  .__label {
    font-size: 11px;
  }

  &.--selected {
    background: linear-gradient(to bottom,#fb02e0 0%,#670078 100%);
  }

  .badge {
    top: 0;
    left: 42px;
    padding: 5px;
  }

}