@import '../utils/utility';
@import '../utils/variables';

.btn {
  display: inline-block;
  vertical-align: top;
  color: inherit;
  margin: mlf(1);
  padding: plf(2) plf(4);
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: $button-font-size;
  font-weight: 600;
  letter-spacing: 2px;
  border: none;
  cursor: pointer;
  background: buttonface;
  background-color: $light-grey-color;
  text-decoration: none;
  line-height: calc(1em - 1px);
  transition: 0.4s;

  &:disabled {
    color: #bbb !important;
    background: $button-disabled-color !important;
    cursor: not-allowed !important;
    transition: 0.3s;
}

  &.--primary {
    color:#fff;
    background-color: $primary-color;
  }

  &.--success {
    color:#fff;
    background-color: $success-color;
  }

  &.--danger {
    color:#fff;
    background-color: $danger-color;
  }

  &.--brand-primary {
    color: #fff;
    background: $brand-primary-gradient-color;
    border-radius: 500px;
  }

  &.--brand-wheel {
    background: $brand-secondary-gradient-color;
    font-size: 16;
  }

  @media screen and (min-width: 700px) {
    &.--brand-secondary {
      padding: plf(1.5) plf(0);
      margin: 0 mlf(3);
      font-size: 20px;
    }
  }

  @media screen and (max-width: 600px) {
    &.--brand-secondary {
      padding: plf(1) plf(4);
      margin: 0 mlf(0.5);
    }
  }

  &.--brand-secondary {
    color: #000;
    background: $brand-secondary-gradient-color;
    border-radius: $input-border-radius;
    font-weight: 800;
    font-style: italic;
    letter-spacing: 1px;
    text-align: center;
    flex: 1 1 0;
  }

  &.--brand-secondary-rounded {
    color: #000;
    background: $brand-secondary-gradient-color;
    border-radius: 500px;
    font-weight: 800;
    font-style: italic;
    letter-spacing: 1px;
  }

  &.--brand-success {
    color: #fff;
    background: $brand-success-gradient-color;
    border-radius: $input-border-radius;
    border-radius: 500px;
  }

  &.--brand-danger {
    color: #fff;
    background: $brand-danger-gradient-color;
    border-radius: $input-border-radius;
    border-radius: 500px;
  }

  &.--block {
    display: block;
    width: calc(100% - #{mlf(2)});
  }
}
