.batch-set-info {
  padding: 0 8px;
}

.status-text {
  padding-right: 8px;
  line-height: 1 !important;

}

.success {
  color: green
}

.fail {
  color: red
}
