.af-edgehanger {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 100;

  &.--top {
    top: 0;
  }

  &.--bottom {
    bottom: 0;
  }
}