@import '../utils/utility';

.component-flex {
  display: flex;
  position: relative;
  flex: 1 1 auto;

  &.--column {
    flex-direction: column;
  }

  &.--no-grow {
    flex: 0 1 auto;
  }
}