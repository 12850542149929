@import '../utils/utility';
@import '../utils/variables';
@import '../utils/shadow';

.af-select {
  padding: 8px;
  box-sizing: border-box;
  position: relative;

  &.--inline {
    display: inline-block;

    .select-wrapper select{
      padding-right: plf(5);
    }
  }

  label {
    font-size: $input-label-font-size;
    margin-bottom: plf(1);
    letter-spacing: 1px;
    display: block;
    font-weight: 600;
  }

  .select-wrapper {
    max-width: 470px;
    position: relative;

    &::after {
      content: '>';
      font-size: 20px;
      transform: rotate(90deg);
      right: 13px;
      bottom: 6px;
      position: absolute;
      pointer-events: none;
    }

    select {
      appearance: none;
      background: none;
      display: block;
      width: 100%;
      padding: plf(1) plf(2);
      font-size: $input-value-font-size;
      font-family: 'Arial', sans-serif;
      letter-spacing: 1px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      border-radius: $input-border-radius;
      outline: none;
      transition: all 0.5s cubic-bezier(.25,.8,.25,1);
      @include shadow(1);
    }
  }
}