@import '../utils/utility';
@import '../utils/variables';

h1.H1 { font-size: 1.6em; }
h2.H2 { font-size: 1.3em; font-weight: 400 !important; }
h3.H3 { font-size: 0.9em; }
p.P {
  margin-bottom: mlf(2);
  line-height: 1.4;

  &.--small {
    font-size: 0.8em;
  }

  b {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  code {
    display: inline;
    margin: 0 mlf(0.5);
    padding: plf(0.5) plf(1);
    font-family: monospace;
    background-color: #e8e8e8;
  }
}

.af-page-header-text {
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: mlf(2.5);
  margin-bottom: mlf(1);

  .af-tag:first-child {
    margin-left: mlf(2);
  }

  .af-tag {
    font-size: 0.65rem;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
    vertical-align: top;
    margin-top: 0;
  }
}

.af-page {
  position: relative;
  max-width: $page-max-width;
  margin: mlf(2) auto;
  box-sizing: border-box;
  border-radius: 5px;

  &.--paper-white {
    background-color: #fff;
    padding: plf(5) plf(4);
    margin-bottom: mlf(4);
    // min-height: calc(100vh - 63px);

    @media only screen and (max-width: 450px) {
      padding: plf(1) plf(2);
      margin: mlf(1);
    }
  }

  &.--branded {
    background: linear-gradient(to bottom, #27012f 0%, #580066 100%);
    padding: plf(5) plf(4);
    margin-bottom: mlf(4);

    @media only screen and (max-width: 450px) {
      padding: plf(1) plf(2);
      margin: mlf(1);
    }
  }

  &.--fluid {
    max-width: 100%
  }

  &.--no-margin {
    margin: 0;
  }

  @media only screen and (max-width: $page-max-width) {
    & {
      margin: mlf(2) mlf(2.5);
    }
  }

  section.af-page-section {
    padding: plf(2) 0;

    .af-page-header-text:nth-child(1) {
      margin-top: 0;
    }
  }
}

.af-page-header {
  margin: plf(-5) plf(-4) plf(3) plf(-4); 
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @media only screen and (max-width: 450px) {
    margin: plf(-1) plf(-2) plf(3) plf(-2);
  }
}

.af-page-footer {
  color: #fff;
  background-color: #bbb;
  padding: plf(5) plf(6);
  margin: plf(3) plf(-4) plf(-5) plf(-4); 
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @media only screen and (max-width: 450px) {
    padding: plf(4) plf(2) plf(2) plf(2);
    margin: plf(3) plf(-2) plf(-1) plf(-2);
  }
}

.af-layout {
  display: grid;

  section.af-page-section {
    padding: plf(3) plf(3);
  }
}

.af-code {
  font-family: monospace;
  font-size: 15px;
  display: block;
  padding: plf(3);
  line-height: 1.55;
  background-color: #e8e8e8;
}
