@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap);
h1.H1 {
  font-size: 1.6em; }

h2.H2 {
  font-size: 1.3em;
  font-weight: 400 !important; }

h3.H3 {
  font-size: 0.9em; }

p.P {
  margin-bottom: 16px;
  line-height: 1.4; }
  p.P.\--small {
    font-size: 0.8em; }
  p.P b {
    font-weight: 600; }
  p.P em {
    font-style: italic; }
  p.P code {
    display: inline;
    margin: 0 4px;
    padding: 3px 6px;
    font-family: monospace;
    background-color: #e8e8e8; }

.af-page-header-text {
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 8px; }
  .af-page-header-text .af-tag:first-child {
    margin-left: 16px; }
  .af-page-header-text .af-tag {
    font-size: 0.65rem;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
    vertical-align: top;
    margin-top: 0; }

.af-page {
  position: relative;
  max-width: 950px;
  margin: 16px auto;
  box-sizing: border-box;
  border-radius: 5px; }
  .af-page.\--paper-white {
    background-color: #fff;
    padding: 30px 24px;
    margin-bottom: 32px; }
    @media only screen and (max-width: 450px) {
      .af-page.\--paper-white {
        padding: 6px 12px;
        margin: 8px; } }
  .af-page.\--branded {
    background: -webkit-linear-gradient(top, #27012f 0%, #580066 100%);
    background: linear-gradient(to bottom, #27012f 0%, #580066 100%);
    padding: 30px 24px;
    margin-bottom: 32px; }
    @media only screen and (max-width: 450px) {
      .af-page.\--branded {
        padding: 6px 12px;
        margin: 8px; } }
  .af-page.\--fluid {
    max-width: 100%; }
  .af-page.\--no-margin {
    margin: 0; }
  @media only screen and (max-width: 950px) {
    .af-page {
      margin: 16px 20px; } }
  .af-page section.af-page-section {
    padding: 12px 0; }
    .af-page section.af-page-section .af-page-header-text:nth-child(1) {
      margin-top: 0; }

.af-page-header {
  margin: -30px -24px 18px -24px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }
  @media only screen and (max-width: 450px) {
    .af-page-header {
      margin: -6px -12px 18px -12px; } }

.af-page-footer {
  color: #fff;
  background-color: #bbb;
  padding: 30px 36px;
  margin: 18px -24px -30px -24px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }
  @media only screen and (max-width: 450px) {
    .af-page-footer {
      padding: 24px 12px 12px 12px;
      margin: 18px -12px -6px -12px; } }

.af-layout {
  display: grid; }
  .af-layout section.af-page-section {
    padding: 18px 18px; }

.af-code {
  font-family: monospace;
  font-size: 15px;
  display: block;
  padding: 18px;
  line-height: 1.55;
  background-color: #e8e8e8; }



.component-breadcrumb {
  padding: 12px 0; }
  .component-breadcrumb li.crumb {
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600; }
    .component-breadcrumb li.crumb a {
      color: #3456cd;
      text-decoration: none; }
  .component-breadcrumb li.crumb:not(:first-child):before {
    content: '/';
    padding: 0 6px; }

.btn {
  display: inline-block;
  vertical-align: top;
  color: inherit;
  margin: 8px;
  padding: 12px 24px;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  border: none;
  cursor: pointer;
  background: buttonface;
  background-color: #e8e8e8;
  text-decoration: none;
  line-height: calc(1em - 1px);
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .btn:disabled {
    color: #bbb !important;
    background: -webkit-linear-gradient(left, #89a 0%, #89a 100%) !important;
    background: linear-gradient(to right, #89a 0%, #89a 100%) !important;
    cursor: not-allowed !important;
    -webkit-transition: 0.3s;
    transition: 0.3s; }
  .btn.\--primary {
    color: #fff;
    background-color: #3456cd; }
  .btn.\--success {
    color: #fff;
    background-color: #08ad15; }
  .btn.\--danger {
    color: #fff;
    background-color: #cd3456; }
  .btn.\--brand-primary {
    color: #fff;
    background: -webkit-linear-gradient(left, #8d02a2 0%, #de01d0 100%);
    background: linear-gradient(to right, #8d02a2 0%, #de01d0 100%);
    border-radius: 500px; }
  .btn.\--brand-wheel {
    background: -webkit-linear-gradient(top, #ffe102 0%, #feb700 100%);
    background: linear-gradient(to bottom, #ffe102 0%, #feb700 100%);
    font-size: 16; }
  @media screen and (min-width: 700px) {
    .btn.\--brand-secondary {
      padding: 9px 0px;
      margin: 0 24px;
      font-size: 20px; } }
  @media screen and (max-width: 600px) {
    .btn.\--brand-secondary {
      padding: 6px 24px;
      margin: 0 4px; } }
  .btn.\--brand-secondary {
    color: #000;
    background: -webkit-linear-gradient(top, #ffe102 0%, #feb700 100%);
    background: linear-gradient(to bottom, #ffe102 0%, #feb700 100%);
    border-radius: 4px;
    font-weight: 800;
    font-style: italic;
    letter-spacing: 1px;
    text-align: center;
    -webkit-flex: 1 1;
            flex: 1 1; }
  .btn.\--brand-secondary-rounded {
    color: #000;
    background: -webkit-linear-gradient(top, #ffe102 0%, #feb700 100%);
    background: linear-gradient(to bottom, #ffe102 0%, #feb700 100%);
    border-radius: 500px;
    font-weight: 800;
    font-style: italic;
    letter-spacing: 1px; }
  .btn.\--brand-success {
    color: #fff;
    background: -webkit-linear-gradient(left, #415ad0 0%, #0e8844 100%);
    background: linear-gradient(to right, #415ad0 0%, #0e8844 100%);
    border-radius: 4px;
    border-radius: 500px; }
  .btn.\--brand-danger {
    color: #fff;
    background: -webkit-linear-gradient(left, #590000 0%, #e10000 100%);
    background: linear-gradient(to right, #590000 0%, #e10000 100%);
    border-radius: 4px;
    border-radius: 500px; }
  .btn.\--block {
    display: block;
    width: calc(100% - 16px); }

.component-callout {
  padding: 18px; }
  .component-callout.\--primary {
    color: #fff;
    background-color: #3456cd; }
  .component-callout.\--success {
    color: #fff;
    background-color: #08ad15; }
  .component-callout.\--danger {
    color: #fff;
    background-color: #cd3456; }

.af-carousel .__control {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 4px; }
  .af-carousel .__control .plain {
    padding: 0;
    border: none;
    background: none;
    outline: 0;
    font-size: 40px;
    font-weight: 900; }
  .af-carousel .__control .reverse {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.af-edgehanger {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 100; }
  .af-edgehanger.\--top {
    top: 0; }
  .af-edgehanger.\--bottom {
    bottom: 0; }

.component-flex {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto; }
  .component-flex.\--column {
    -webkit-flex-direction: column;
            flex-direction: column; }
  .component-flex.\--no-grow {
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto; }

.af-flying-text {
  overflow: hidden;
  padding: 6px 0;
  background: -webkit-linear-gradient(top, #ffe102 0%, #feb700 100%);
  background: linear-gradient(to bottom, #ffe102 0%, #feb700 100%);
  height: 10px; }
  .af-flying-text .flying-content {
    display: inline-block;
    min-width: 100%;
    white-space: nowrap;
    padding: 0 12px;
    box-sizing: border-box;
    overflow: hidden;
    -webkit-animation-name: fly;
            animation-name: fly;
    -webkit-animation-duration: 35s;
            animation-duration: 35s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    color: #000; }

@-webkit-keyframes fly {
  from {
    -webkit-transform: translateX(100vw);
            transform: translateX(100vw); }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@keyframes fly {
  from {
    -webkit-transform: translateX(100vw);
            transform: translateX(100vw); }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }


.component-input-element {
  padding: 8px;
  box-sizing: border-box; }
  .component-input-element.\--inline {
    display: inline-block; }
  .component-input-element label {
    font-size: 12px;
    margin-bottom: 6px;
    letter-spacing: 1px;
    display: block;
    font-weight: 600; }
  .component-input-element input {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
    letter-spacing: 1px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    -webkit-transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
    .component-input-element input::-webkit-input-placeholder {
      text-align: center; }
    .component-input-element input::-ms-input-placeholder {
      text-align: center; }
    .component-input-element input::placeholder {
      text-align: center; }
  .component-input-element input[type=text], .component-input-element input[type=password] {
    max-width: 470px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  .component-input-element input[type=checkbox], .component-input-element input[type=radio] {
    display: inline-block;
    width: auto;
    vertical-align: top; }
  .component-input-element .checkable-label {
    padding: 3px 6px;
    display: inline-block;
    vertical-align: top; }
  .component-input-element textarea:focus, .component-input-element input:focus {
    border: 1px solid rgba(45, 67, 200, 0.5); }
  .component-input-element textarea {
    display: block;
    width: 100%;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 1.6;
    box-sizing: border-box;
    border: 1px solid #ccc;
    padding: 9.6px 12px;
    resize: none;
    outline: none;
    -webkit-transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .component-input-element .react-datepicker {
    font-family: "Open Sans", sans-serif; }
  .component-input-element .react-datepicker-wrapper, .component-input-element .react-datepicker__input-container {
    display: block; }
  .component-input-element .react-datepicker__day--selected {
    background-color: #3456cd; }
  .component-input-element .react-datepicker__portal .react-datepicker__current-month {
    font-size: 1rem;
    margin-top: 7.2px; }
  .component-input-element .react-datepicker__portal .react-datepicker__week {
    font-size: 1.2rem; }
  .component-input-element .react-datepicker__portal .react-datepicker-time__header {
    font-size: 0.8rem;
    padding: 6px; }
  .component-input-element .react-datepicker__portal .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    font-size: 1.1rem;
    padding: 12px 0; }
  .component-input-element .react-datepicker__today-button {
    padding: 12px; }
  .component-input-element .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    width: 100%;
    display: block;
    padding: 8.4px 0; }
  .component-input-element .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #3456cd; }
  .component-input-element .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    display: none; }
  .component-input-element .react-datepicker__header__dropdown {
    padding: 12px; }
    .component-input-element .react-datepicker__header__dropdown .react-datepicker__month-read-view--down-arrow, .component-input-element .react-datepicker__header__dropdown .react-datepicker__year-read-view--down-arrow {
      top: 4px;
      margin-left: 32px; }
  @media only screen and (max-width: 533px) {
    .component-input-element .react-datepicker__portal {
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
      .component-input-element .react-datepicker__portal .react-datepicker__month-container .react-datepicker__day-name {
        width: calc(15vw - 10px); }
      .component-input-element .react-datepicker__portal .react-datepicker__month-container .react-datepicker__day {
        width: calc(15vw - 10px); }
      .component-input-element .react-datepicker__portal .\--mobile-with-timepicker .react-datepicker__month-container .react-datepicker__day-name {
        width: calc(15vw - 20px); }
      .component-input-element .react-datepicker__portal .\--mobile-with-timepicker .react-datepicker__month-container .react-datepicker__day {
        width: calc(15vw - 20px); } }

.af-image {
  display: block; }
  .af-image img {
    display: block;
    width: 100%;
    height: auto; }

.af-json-input {
  padding: 8px;
  box-sizing: border-box; }
  .af-json-input label {
    font-size: 12px;
    margin-bottom: 6px;
    letter-spacing: 2px;
    display: block;
    text-transform: uppercase;
    font-weight: 600; }
  .af-json-input input {
    display: inline-block;
    padding: 6px 12px;
    font-size: 16px;
    letter-spacing: 2px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    outline: none;
    -webkit-transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .af-json-input input:focus {
    border: 1px solid rgba(45, 67, 200, 0.5); }
  .af-json-input .key-value-pair {
    padding: 3px 0; }
  .af-json-input .input-key {
    width: calc(44% - 21px);
    margin-right: 4px; }
  .af-json-input .input-value {
    width: calc(56% - 21px); }
  .af-json-input .btn-remove {
    padding: 9.9px;
    margin: 0;
    margin-left: 4px; }

.af-link {
  color: #3456cd; }

.component-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  z-index: 200;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  .component-loader.\--transparent {
    opacity: 0; }
  .component-loader.\--unmount {
    display: none; }

.af-infinite-date-picker .date-picker-overlay-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000; }
  .af-infinite-date-picker .date-picker-overlay-container .date-picker-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    min-height: 100vh;
    min-width: 100vw; }
    .af-infinite-date-picker .date-picker-overlay-container .date-picker-wrapper .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 100; }
    .af-infinite-date-picker .date-picker-overlay-container .date-picker-wrapper .date-picker-container {
      margin: 0 64px;
      max-width: 806px;
      z-index: 200; }

.af-interruptive-loader-module {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; }
  .af-interruptive-loader-module .loader-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    min-height: 100vh;
    min-width: 100vw; }
    .af-interruptive-loader-module .loader-wrapper .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 100;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear; }
    .af-interruptive-loader-module .loader-wrapper .loader {
      background-color: #fff;
      width: 100vw;
      z-index: 200;
      box-sizing: border-box;
      padding: 60px 60px 30px 60px;
      max-height: calc(70vh - 30px);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear; }
      .af-interruptive-loader-module .loader-wrapper .loader p {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 1px;
        text-align: center; }
  .af-interruptive-loader-module .spinner {
    color: #3456cd;
    font-size: 40px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 0 auto 56px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease; }

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em; }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em; }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em; }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; } }

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em; }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em; }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em; }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; } }

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.af-modal-module {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; }
  .af-modal-module .modal-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    min-height: 100vh;
    min-width: 100vw; }
  .af-modal-module .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear; }
  .af-modal-module .modal {
    background-color: #fff;
    margin: 0 64px;
    max-width: 806px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    z-index: 200;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    @media only screen and (max-width: 533px) {
      .af-modal-module .modal {
        max-width: 100%;
        width: 100%;
        margin: 0; } }
    .af-modal-module .modal.\--padded {
      padding: 33px;
      overflow-y: auto;
      max-height: calc(70vh - 30px); }
    .af-modal-module .modal .__header {
      padding: 24px;
      color: #fff;
      background-color: #3456cd; }
      .af-modal-module .modal .__header .af-page-header {
        margin-top: 0; }
      .af-modal-module .modal .__header i {
        margin-left: 8px; }
      .af-modal-module .modal .__header.\--success {
        background-color: #08ad15; }
      .af-modal-module .modal .__header.\--danger {
        background-color: #cd3456; }
      .af-modal-module .modal .__header.\--brand-primary {
        background: -webkit-linear-gradient(left, #8d02a2 0%, #de01d0 100%);
        background: linear-gradient(to right, #8d02a2 0%, #de01d0 100%); }
      .af-modal-module .modal .__header.\--brand-secondary {
        color: black;
        background: -webkit-linear-gradient(top, #ffe102 0%, #feb700 100%);
        background: linear-gradient(to bottom, #ffe102 0%, #feb700 100%); }
      .af-modal-module .modal .__header.\--brand-error {
        background: white; }
    .af-modal-module .modal .__body {
      padding: 24px;
      overflow-y: auto;
      max-height: calc(70vh - 144px); }
      .af-modal-module .modal .__body p:last-child {
        margin-bottom: 0; }
    .af-modal-module .modal .__footer {
      padding: 0 24px 12px 24px; }

.component-menu-bar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  box-sizing: border-box; }
  .component-menu-bar .menu-bar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    padding: 12px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    .component-menu-bar .menu-bar .burger-icon {
      cursor: pointer;
      border: 0;
      background: none; }
  .component-menu-bar .the-menu-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    z-index: 200; }
    .component-menu-bar .the-menu-container aside {
      position: absolute;
      color: #fff;
      top: 0;
      left: 0;
      height: 100%;
      overflow: auto;
      width: 85vw;
      max-width: 300px;
      background-color: #132330;
      z-index: 200;
      -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 6px 6px 6px rgba(0, 0, 0, 0.23); }
      .component-menu-bar .the-menu-container aside .close {
        position: absolute;
        top: 0px;
        right: 4px;
        padding: 12px;
        border: 0;
        background: none;
        color: #fff;
        font-size: 22px;
        font-weight: 600; }
      .component-menu-bar .the-menu-container aside header {
        padding: 24px 36px 36px 12px; }
        .component-menu-bar .the-menu-container aside header h1 {
          font-size: 12px;
          font-weight: 300;
          letter-spacing: 3px;
          margin-bottom: 6px; }
        .component-menu-bar .the-menu-container aside header h2 {
          font-size: 13px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 3px;
          text-transform: uppercase; }
      .component-menu-bar .the-menu-container aside ul li {
        display: block;
        cursor: pointer;
        padding: 18px; }
        .component-menu-bar .the-menu-container aside ul li .btn-menu {
          text-decoration: none;
          display: block;
          color: inherit; }
        .component-menu-bar .the-menu-container aside ul li span {
          margin-left: 36px; }
        .component-menu-bar .the-menu-container aside ul li span.badge {
          margin-left: 12px; }
      .component-menu-bar .the-menu-container aside ul li:active {
        background-color: #233350; }
    .component-menu-bar .the-menu-container .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .component-menu-bar .badge {
    background-color: #fa3e3e;
    border-radius: 10px;
    color: white;
    padding: 1px 6px;
    font-size: 10px;
    vertical-align: middle;
    z-index: 2; }
  .component-menu-bar .badge.abs {
    position: relative;
    top: -7px;
    left: -4px;
    padding: 0 5px; }

.af-navbar {
  display: -webkit-flex;
  display: flex;
  position: relative;
  min-width: 100%;
  min-height: 40px;
  padding: 6px 1.8px;
  background-color: #1e0829;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.af-navbar-item {
  -webkit-flex: 1 1 20%;
          flex: 1 1 20%;
  padding: 6px;
  margin: 0 2.4px;
  text-decoration: none;
  display: block;
  color: #ffc600;
  background: -webkit-linear-gradient(top, #9100a7 0%, #670078 100%);
  background: linear-gradient(to bottom, #9100a7 0%, #670078 100%);
  display: block;
  border-radius: 5px; }
  .af-navbar-item .__icon, .af-navbar-item .__label {
    text-align: center; }
  .af-navbar-item .__icon-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 40px; }
    .af-navbar-item .__icon-container .__icon {
      width: 32px; }
  .af-navbar-item .__label {
    font-size: 11px; }
  .af-navbar-item.\--selected {
    background: -webkit-linear-gradient(top, #fb02e0 0%, #670078 100%);
    background: linear-gradient(to bottom, #fb02e0 0%, #670078 100%); }
  .af-navbar-item .badge {
    top: 0;
    left: 42px;
    padding: 5px; }

.af-select {
  padding: 8px;
  box-sizing: border-box;
  position: relative; }
  .af-select.\--inline {
    display: inline-block; }
    .af-select.\--inline .select-wrapper select {
      padding-right: 30px; }
  .af-select label {
    font-size: 12px;
    margin-bottom: 6px;
    letter-spacing: 1px;
    display: block;
    font-weight: 600; }
  .af-select .select-wrapper {
    max-width: 470px;
    position: relative; }
    .af-select .select-wrapper::after {
      content: '>';
      font-size: 20px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      right: 13px;
      bottom: 6px;
      position: absolute;
      pointer-events: none; }
    .af-select .select-wrapper select {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      background: none;
      display: block;
      width: 100%;
      padding: 6px 12px;
      font-size: 16px;
      font-family: 'Arial', sans-serif;
      letter-spacing: 1px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;
      -webkit-transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }


.af-table .control-group {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.af-table .pagination {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.af-table.\--centered-content .rt-td {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.ReactTable {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .ReactTable * {
    box-sizing: border-box; }
  .ReactTable .rt-table {
    -webkit-flex: auto 1;
            flex: auto 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: stretch;
            align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto; }
  .ReactTable .rt-thead {
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .ReactTable .rt-thead.-headerGroups {
      background: blue;
      color: white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
    .ReactTable .rt-thead.-filters {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
      .ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 5px 7px;
        font-size: inherit;
        border-radius: 3px;
        font-weight: normal;
        outline: none; }
      .ReactTable .rt-thead.-filters .rt-th {
        border-right: 1px solid rgba(0, 0, 0, 0.02); }
    .ReactTable .rt-thead.-header {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
      background: #ccc; }
    .ReactTable .rt-thead .rt-tr {
      text-align: center; }
    .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
      padding: 5px 5px;
      line-height: normal;
      position: relative;
      border-right: 1px solid rgba(0, 0, 0, 0.5);
      -webkit-transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      box-shadow: inset 0 0 0 0 transparent; }
      .ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
        box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6); }
      .ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
        box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6); }
      .ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
        cursor: pointer; }
      .ReactTable .rt-thead .rt-th:last-child, .ReactTable .rt-thead .rt-td:last-child {
        border-right: 0; }
    .ReactTable .rt-thead .rt-resizable-header {
      overflow: visible; }
      .ReactTable .rt-thead .rt-resizable-header:last-child {
        overflow: hidden; }
    .ReactTable .rt-thead .rt-resizable-header-content {
      overflow: hidden;
      text-overflow: ellipsis; }
    .ReactTable .rt-thead .rt-header-pivot {
      border-right-color: #f7f7f7; }
      .ReactTable .rt-thead .rt-header-pivot:after, .ReactTable .rt-thead .rt-header-pivot:before {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none; }
      .ReactTable .rt-thead .rt-header-pivot:after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #fff;
        border-width: 8px;
        margin-top: -8px; }
      .ReactTable .rt-thead .rt-header-pivot:before {
        border-color: rgba(102, 102, 102, 0);
        border-left-color: #f7f7f7;
        border-width: 10px;
        margin-top: -10px; }
  .ReactTable .rt-tbody {
    -webkit-flex: 99999 1 auto;
            flex: 99999 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow: auto; }
    .ReactTable .rt-tbody .rt-tr-group {
      border-bottom: solid 1px rgba(0, 0, 0, 0.05); }
      .ReactTable .rt-tbody .rt-tr-group:last-child {
        border-bottom: 0; }
    .ReactTable .rt-tbody .rt-td {
      border-right: 1px solid rgba(0, 0, 0, 0.5); }
      .ReactTable .rt-tbody .rt-td:last-child {
        border-right: 0; }
    .ReactTable .rt-tbody .rt-expandable {
      cursor: pointer;
      text-overflow: clip; }
  .ReactTable .rt-tr-group {
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: stretch;
            align-items: stretch; }
  .ReactTable .rt-tr {
    display: -webkit-inline-flex;
    display: inline-flex; }
  .ReactTable .rt-th, .ReactTable .rt-td {
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    -webkit-transition: .3s ease;
    transition: .3s ease;
    -webkit-transition-property: width,min-width,padding,opacity;
    transition-property: width,min-width,padding,opacity; }
    .ReactTable .rt-th.-hidden, .ReactTable .rt-td.-hidden {
      width: 0 !important;
      min-width: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      opacity: 0 !important; }
  .ReactTable .rt-expander {
    display: inline-block;
    position: relative;
    margin: 0;
    color: transparent;
    margin: 0 10px; }
    .ReactTable .rt-expander:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) rotate(-90deg);
              transform: translate(-50%, -50%) rotate(-90deg);
      border-left: 5.04px solid transparent;
      border-right: 5.04px solid transparent;
      border-top: 7px solid rgba(0, 0, 0, 0.8);
      -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      cursor: pointer; }
    .ReactTable .rt-expander.-open:after {
      -webkit-transform: translate(-50%, -50%) rotate(0);
              transform: translate(-50%, -50%) rotate(0); }
  .ReactTable .rt-resizer {
    display: inline-block;
    position: absolute;
    width: 36px;
    top: 0;
    bottom: 0;
    right: -18px;
    cursor: col-resize;
    z-index: 10; }
  .ReactTable .rt-tfoot {
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15); }
    .ReactTable .rt-tfoot .rt-td {
      border-right: 1px solid rgba(0, 0, 0, 0.05); }
      .ReactTable .rt-tfoot .rt-td:last-child {
        border-right: 0; }
  .ReactTable .rt-tr.-even {
    background: #EEEEEE; }
  .ReactTable.-striped .rt-tr.-odd {
    background: rgba(0, 0, 0, 0.03); }
  .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: rgba(0, 0, 0, 0.05); }
  .ReactTable .-pagination {
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.1); }
    .ReactTable .-pagination input, .ReactTable .-pagination select {
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 5px 7px;
      font-size: inherit;
      border-radius: 3px;
      font-weight: normal;
      outline: none; }
    .ReactTable .-pagination .-btn {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: block;
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 3px;
      padding: 6px;
      font-size: 1em;
      color: rgba(0, 0, 0, 0.6);
      background: rgba(0, 0, 0, 0.1);
      -webkit-transition: all .1s ease;
      transition: all .1s ease;
      cursor: pointer;
      outline: none; }
      .ReactTable .-pagination .-btn[disabled] {
        opacity: .5;
        cursor: default; }
      .ReactTable .-pagination .-btn:not([disabled]):hover {
        background: rgba(0, 0, 0, 0.3);
        color: #fff; }
    .ReactTable .-pagination .-previous {
      -webkit-flex: 1 1;
              flex: 1 1;
      text-align: center; }
    .ReactTable .-pagination .-next {
      -webkit-flex: 1 1;
              flex: 1 1;
      text-align: center; }
    .ReactTable .-pagination .-center {
      -webkit-flex: 1.5 1;
              flex: 1.5 1;
      text-align: center;
      margin-bottom: 0;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-around;
              justify-content: space-around; }
    .ReactTable .-pagination .-pageInfo {
      display: inline-block;
      margin: 3px 10px;
      white-space: nowrap; }
    .ReactTable .-pagination .-pageJump {
      display: inline-block; }
      .ReactTable .-pagination .-pageJump input {
        width: 70px;
        text-align: center; }
    .ReactTable .-pagination .-pageSizeOptions {
      margin: 3px 10px; }
  .ReactTable .rt-noData {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 20px;
    color: rgba(0, 0, 0, 0.5); }
  .ReactTable .-loading {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none; }
    .ReactTable .-loading > div {
      position: absolute;
      display: block;
      text-align: center;
      width: 100%;
      top: 50%;
      left: 0;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.6);
      -webkit-transform: translateY(-52%);
              transform: translateY(-52%);
      -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .ReactTable .-loading.-active {
    opacity: 1;
    z-index: 2;
    pointer-events: all; }
    .ReactTable .-loading.-active > div {
      -webkit-transform: translateY(50%);
              transform: translateY(50%); }
  .ReactTable .rt-resizing .rt-th {
    -webkit-transition: none !important;
    transition: none !important;
    cursor: col-resize;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .ReactTable .rt-resizing .rt-td {
    -webkit-transition: none !important;
    transition: none !important;
    cursor: col-resize;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.af-tabular {
  min-height: auto; }
  .af-tabular .row {
    display: -webkit-flex;
    display: flex;
    text-decoration: none;
    color: #000;
    margin: 0 8px;
    padding: 9px;
    border-bottom: 1px solid #ccc; }
    .af-tabular .row .static-cell {
      -webkit-flex: 1 1 auto;
              flex: 1 1 auto; }
      .af-tabular .row .static-cell .header {
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 8px; }
      .af-tabular .row .static-cell .subheader {
        font-size: 0.9rem; }
      .af-tabular .row .static-cell .status {
        padding-left: 8px; }
    .af-tabular .row .dynamic-cell {
      -webkit-flex: 0 1 auto;
              flex: 0 1 auto; }
      .af-tabular .row .dynamic-cell.\--bold {
        font-weight: 600; }
    .af-tabular .row .arrow-sign {
      -webkit-flex: 0 1 auto;
              flex: 0 1 auto;
      font-size: 0.8rem;
      padding-left: 18px;
      -webkit-align-self: center;
              align-self: center; }
  .af-tabular.\--no-data {
    color: #aaa;
    padding: 18px;
    text-align: center; }
  .af-tabular.\--branded {
    border: 1px solid #feb700; }
    .af-tabular.\--branded .row {
      color: #fff;
      border-bottom: 1px solid #feb700; }
  .af-tabular .rotate {
    -webkit-animation: rotateAnimation 0.5s linear;
            animation: rotateAnimation 0.5s linear; }

@-webkit-keyframes rotateAnimation {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0); }
  100% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); } }

@keyframes rotateAnimation {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0); }
  100% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); } }

.af-tag {
  display: inline-block;
  font-size: 12px;
  padding: 6px 9px;
  margin: 4px;
  background-color: buttonface; }
  .af-tag.\--primary {
    color: #fff;
    background-color: #3456cd; }
  .af-tag.\--success {
    color: #fff;
    background-color: #08ad15; }
  .af-tag.\--danger {
    color: #fff;
    background-color: #cd3456; }

.af-toggle-group {
  position: relative;
  padding: 12px 6px;
  overflow-x: auto;
  white-space: nowrap; }
  .af-toggle-group .af-tab {
    position: relative;
    display: inline-block; }
    .af-toggle-group .af-tab .badge {
      top: 7px;
      left: 84px;
      padding: 5px; }
    .af-toggle-group .af-tab input {
      position: absolute;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      margin: 0; }
    .af-toggle-group .af-tab input[type="radio"] + label,
    .af-toggle-group .af-tab input[type="checkbox"] + label {
      display: block;
      font-size: 15px;
      letter-spacing: 1px;
      padding: 6px 18px;
      color: #3456cd;
      border: 1px #3456cd solid;
      border-right: none; }
    .af-toggle-group .af-tab input[type="radio"]:checked + label,
    .af-toggle-group .af-tab input[type="checkbox"]:checked + label {
      color: #fff;
      background-color: #3456cd;
      font-weight: 300; }
  .af-toggle-group .af-tab:first-child input[type="radio"] + label,
  .af-toggle-group .af-tab:first-child input[type="checkbox"] + label {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  .af-toggle-group .af-tab:last-child input[type="radio"] + label,
  .af-toggle-group .af-tab:last-child input[type="checkbox"] + label {
    border-right: 1px #3456cd solid;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  .af-toggle-group.\--branded {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    min-width: 100%;
    padding: 0; }
    .af-toggle-group.\--branded .af-tab {
      -webkit-flex: 1 1;
              flex: 1 1;
      display: block; }
      .af-toggle-group.\--branded .af-tab input[type="radio"] + label,
      .af-toggle-group.\--branded .af-tab input[type="checkbox"] + label {
        padding: 12px 24px;
        color: #fff;
        text-align: center;
        background: -webkit-linear-gradient(left, #71008b 0%, #5a4ab4 100%);
        background: linear-gradient(to right, #71008b 0%, #5a4ab4 100%);
        letter-spacing: 0px;
        border: none;
        text-transform: uppercase;
        font-weight: 800;
        font-style: italic; }
      .af-toggle-group.\--branded .af-tab input[type="radio"]:checked + label,
      .af-toggle-group.\--branded .af-tab input[type="checkbox"]:checked + label {
        font-weight: inherit;
        background: -webkit-linear-gradient(left, #fff 0%, #fff 100%);
        background: linear-gradient(to right, #fff 0%, #fff 100%);
        color: #590000; }
    .af-toggle-group.\--branded .af-tab:first-child input[type="radio"] + label,
    .af-toggle-group.\--branded .af-tab:first-child input[type="checkbox"] + label {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }
    .af-toggle-group.\--branded .af-tab:last-child input[type="radio"] + label,
    .af-toggle-group.\--branded .af-tab:last-child input[type="checkbox"] + label {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }

.switch-container {
  padding: 4px 8px;
  -webkit-align-self: center;
          align-self: center; }

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 26px; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

input:checked + .slider {
  background-color: green; }

input:focus + .slider {
  box-shadow: 0 0 1px green; }

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  transform: translateX(16px); }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

button:focus {outline:0;}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.scope-kitchen-sink {
  padding: 48px 0 32px 0;
  min-height: 100vh;
  box-sizing: border-box; }

.page-login .header {
  background-color: #123123;
  color: white;
  padding: 16px; }
  .page-login .header img {
    display: block;
    margin: 0 auto; }
  .page-login .header h1 {
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 5px;
    text-transform: uppercase;
    padding: 22px 0; }

.page-login .input-element {
  padding: 8px;
  box-sizing: border-box; }
  .page-login .input-element label {
    font-size: 12px;
    margin-bottom: 6px;
    letter-spacing: 2px;
    display: block;
    text-transform: uppercase;
    font-weight: 600; }
  .page-login .input-element input {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 16px;
    letter-spacing: 2px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    outline: none;
    -webkit-transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .page-login .input-element input:focus {
    border: 1px solid rgba(45, 67, 200, 0.5); }

.toggle-swithc-label {
  margin: 0;
  align-Self: center; }

.component-agent-score-balance {
  -webkit-align-items: center;
          align-items: center;
  justify-self: flex-end; }

.refresh-button {
  padding: 12px;
  border-radius: 20px; }

.refresh-button.balance-report {
  margin-left: auto; }

@media screen and (max-width: 400px) {
  .toggle-swithc-label {
    display: none; } }

.scope-workspace {
  box-sizing: border-box;
  background-color: #efeff4; }



.batch-set-info {
  padding: 0 8px; }

.status-text {
  padding-right: 8px;
  line-height: 1 !important; }

.success {
  color: green; }

.fail {
  color: red; }

.static-element {
  margin-top: 4px;
  margin-bottom: 16px; }
  .static-element label {
    display: block;
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 4px; }
  .static-element .__primary {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px; }
    .static-element .__primary small {
      font-size: 0.85em;
      font-weight: 400; }
  .static-element .__secondary {
    font-size: 12px;
    margin-bottom: 1px; }
  .static-element .__actionable {
    font-size: 14px;
    display: block;
    text-decoration: underline;
    color: #3456cd;
    margin-top: 6px; }

.static-element.\--center {
  text-align: center; }

.scope-app {
  background-color: #e8e8e8;
  font-family: "Open Sans", sans-serif;
  position: relative;
  min-height: 90vh; }

.paddingLeft {
  padding-left: 8px; }

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px; }

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5; }

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555; }

