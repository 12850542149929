.toggle-swithc-label {
  margin: 0;
  align-Self: center;
}

.component-agent-score-balance {
  align-items: center;
  justify-self: flex-end;
}

.refresh-button {
  padding: 12px;
  border-radius: 20px;
}
.refresh-button.balance-report {
  margin-left: auto;
}


@media screen and (max-width: 400px) {
  .toggle-swithc-label {
    display: none;
  }
}
