@import '../utils/utility';
@import '../utils/variables';

.af-flying-text {
  overflow: hidden;
  padding: plf(1) 0;
  background: $brand-secondary-gradient-color;
  height: 10px;

  .flying-content {
    display: inline-block;
    min-width: 100%;
    white-space: nowrap;
    padding: 0 plf(2);
    box-sizing: border-box;
    overflow: hidden;
    animation-name: fly;
    animation-duration: 35s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    color: #000;
  }

  @keyframes fly {
    from {
      transform: translateX(100vw);
    }

    to {
      transform: translateX(-100%);
    }
  }

}