.component-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  z-index: 200;
  transition: all 0.3s ease-out;

  &.--transparent {
    opacity: 0;
  }

  &.--unmount {
    display: none;
  }
}
