@import '../utils/utility';
@import '../utils/variables';
@import '../utils/shadow';

.af-modal-module {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
    transition: all 0.4s linear;
  }

  .modal {
    background-color: #fff;
    margin: 0 mlf(8);
    max-width: $page-max-width - plf(24);
    @include shadow(1);
    z-index: 200;
    transition: all 0.3s ease;

    @media only screen and (max-width: $device-sm-width) {
      & {
        max-width: 100%;
        width: 100%;
        margin: 0;
      }
    }

    &.--padded {
      padding: plf(5.5);
      overflow-y: auto;
      max-height: calc(70vh - #{plf(5)});
    }

    .__header {
      padding: plf(4);
      color: #fff;
      background-color: $primary-color;

      .af-page-header {
        margin-top: 0;
      }

      i {
        margin-left: 8px
      }

      &.--success {
        background-color: $success-color;
      }

      &.--danger {
        background-color: $danger-color;
      }

      &.--brand-primary {
        background: $brand-primary-gradient-color;
      }

      &.--brand-secondary {
        color: black;
        background: $brand-secondary-gradient-color;
      }

      &.--brand-error {
        background: white;
      }
    }

    .__body {
      padding: plf(4);
      overflow-y: auto;
      max-height: calc(70vh - #{plf(24)});

      p:last-child {
        margin-bottom: 0;
      }
    }

    .__footer {
      padding: 0 plf(4) plf(2) plf(4);
    }
  }
}
