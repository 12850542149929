@import '../utils/utility';
@import '../utils/variables';

.af-toggle-group {
  position: relative;
  padding: plf(2) plf(1);
  overflow-x: auto;
  white-space: nowrap;

  .af-tab {
    position: relative;
    display: inline-block;

    .badge {
      top: 7px;
      left: 84px;
      padding: 5px;
    }

    input {
      position: absolute;
      appearance: none;
      margin: 0;
    }

    input[type="radio"] + label,
    input[type="checkbox"] + label{
      display: block;
      font-size: 15px;
      letter-spacing: 1px;
      padding: plf(1) plf(3);
      color: $primary-color;
      border: 1px $primary-color solid;
      border-right: none;
    }

    input[type="radio"]:checked + label,
    input[type="checkbox"]:checked + label{
      color: #fff;
      background-color: $primary-color;
      font-weight: 300;
    }
  }

  .af-tab:first-child {
    input[type="radio"] + label,
    input[type="checkbox"] + label{
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }

  .af-tab:last-child {
    input[type="radio"] + label,
    input[type="checkbox"] + label{
      border-right: 1px $primary-color solid;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  &.--branded {
    display: flex;
    flex-direction: row;
    min-width: 100%;
    padding: 0;

    .af-tab {
      flex: 1 1 0;
      display: block;

      input[type="radio"] + label,
      input[type="checkbox"] + label {
        padding: plf(2) plf(4);
        color: #fff;
        text-align: center;
        background: linear-gradient(to right, #71008b 0%, #5a4ab4 100%);
        letter-spacing: 0px;
        border: none;
        text-transform: uppercase;
        font-weight: 800;
        font-style: italic;
      }

      input[type="radio"]:checked + label,
      input[type="checkbox"]:checked + label {
        font-weight: inherit;
        background: linear-gradient(to right, #fff 0%, #fff 100%);
        color: #590000;
      }
    }

    .af-tab:first-child {
      input[type="radio"] + label,
      input[type="checkbox"] + label{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    .af-tab:last-child {
      input[type="radio"] + label,
      input[type="checkbox"] + label{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}