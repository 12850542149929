@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,800i&display=swap');

// Feel
$base-margin-whitespace: 8px;
$base-padding-whitespace: 6px;

// Page
$page-max-width: 950px;

// Device
$device-sm-width: 533px;

// Font
$base-font: 'Open Sans', sans-serif;
$base-font-size: 16px;

// Color
$primary-color: #3456cd;
$success-color: #08ad15;
$danger-color: #cd3456;
$light-grey-color: #e8e8e8;

$brand-primary-gradient-color: linear-gradient(to right,#8d02a2 0%,#de01d0 100%);
$brand-secondary-gradient-color: linear-gradient(to bottom,#ffe102 0%, #feb700 100%);
$brand-success-gradient-color: linear-gradient(to right,#415ad0 0%,#0e8844 100%);
$brand-danger-gradient-color: linear-gradient(to right,#590000 0%,#e10000 100%);

// Button
$button-font-size: 12px;
$button-disabled-color: linear-gradient(to right,#89a 0%,#89a 100%);

// Input
$input-label-font-size: 12px;
$input-value-font-size: 16px;
$input-border-radius: 4px;

:export {
  pageMaxWidth: $page-max-width;
}
