@import '../utils/utility';
@import '../utils/variables';
@import '../utils/shadow';

.component-input-element {
  padding: 8px;
  box-sizing: border-box;

  &.--inline {
    display: inline-block;
  }

  label {
    font-size: $input-label-font-size;
    margin-bottom: plf(1);
    letter-spacing: 1px;
    display: block;
    font-weight: 600;
  }

  input {
    display: block;
    width: 100%;
    padding: plf(1) plf(2);
    font-size: $input-value-font-size;
    font-family: 'Arial', sans-serif;
    letter-spacing: 1px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: $input-border-radius;
    outline: none;
    transition: all 0.5s cubic-bezier(.25,.8,.25,1);

    &::placeholder {
      text-align: center;
    }
  }

  input[type=text], input[type=password] {
    max-width: 470px;
    @include shadow(1);
  }

  input[type=checkbox], input[type=radio] {
    display: inline-block;
    width: auto;
    vertical-align: top;
  }

  .checkable-label {
    padding: 3px plf(1);
    display: inline-block;
    vertical-align: top;
  }

  textarea:focus, input:focus {
    border: 1px solid rgba(45, 67, 200, 0.5);
  }

  textarea {
    display: block;
    width: 100%;
    font-family: 'Arial', sans-serif;
    font-size: $input-value-font-size;
    letter-spacing: 1px;
    line-height: 1.6;
    box-sizing: border-box;
    border: 1px solid #ccc;
    padding: plf(1.6) plf(2);
    resize: none;
    outline: none;
    transition: all 0.5s cubic-bezier(.25,.8,.25,1);
  }

  // React Datepicker
  .react-datepicker {
    font-family: $base-font;
  }

  .react-datepicker-wrapper, .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__day--selected {
    background-color: $primary-color;
  }

  .react-datepicker__portal {
    .react-datepicker__current-month {
      font-size: 1rem;
      margin-top: mlf(0.9);
    }

    .react-datepicker__week {
      font-size: 1.2rem;


    }

    .react-datepicker-time__header {
      font-size: 0.8rem;
      padding: plf(1);
    }

    .react-datepicker__time-container {
      .react-datepicker__time {
        .react-datepicker__time-box {
          ul.react-datepicker__time-list {
            li.react-datepicker__time-list-item {
              font-size: 1.1rem;
              padding: plf(2) 0;
            }
          }
        }
      }
    }
  }

  .react-datepicker__today-button {
    padding: plf(2);
  }

  .react-datepicker__time-container {
    .react-datepicker__time {
      .react-datepicker__time-box {
        ul.react-datepicker__time-list {
          li.react-datepicker__time-list-item {
            width: 100%;
            display: block;
            padding: plf(1.4) 0;
          }

          li.react-datepicker__time-list-item--selected {
            background-color: $primary-color;
          }

          li.react-datepicker__time-list-item--disabled {
            display: none;
          }
        }
      }
    }
  }

  .react-datepicker__header__dropdown {
    padding: plf(2);

    .react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
      top: 4px;
      margin-left: 32px;
    }
  }


  @media only screen and (max-width: 533px) {
    .react-datepicker__portal {
      justify-content: flex-start;

      .react-datepicker__month-container {
        .react-datepicker__day-name {
          width: calc(15vw - 10px);
        }

        .react-datepicker__day {
          width: calc(15vw - 10px);
        }
      }

      .--mobile-with-timepicker {
        .react-datepicker__month-container {
          .react-datepicker__day-name {
            width: calc(15vw - 20px);
          }

          .react-datepicker__day {
            width: calc(15vw - 20px);
          }
        }
      }
    }
  }
}

